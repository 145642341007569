<template>
  <div class="post-subscribe-wrap" v-if="post.media && post.media.length">
    <MarkOffer :text="`${discountPercent}% Off`" :post="post" v-if="isOffer" />
    <div class="post-subscribe" v-if="post">
      <div class="post-subscribe-icon">
        <figure><iconic name="lock-solid" /></figure>
      </div>
      <div class="post-subscribe-content">
        <CreatorPostSubscribeDiscount :post="post" v-if="onDiscount(post)" class="subscribe-message-p" />
        <CreatorPostSubscribeRegular :post="post" v-else class="subscribe-message-p" />
        <SubscribeButton @click="subscribe(post)" />
      </div>
    </div>
  </div>
</template>

<script>
import SubscribeButton from "../buttons/SubscribeButton.vue";
import CreatorPostSubscribeRegular from "./CreatorPostSubscribeRegular.vue";
import CreatorPostSubscribeDiscount from "./CreatorPostSubscribeDiscount.vue";
import MarkOffer from "../buttons/MarkOffer.vue";
export default {
  props: ["post"],
  components: { SubscribeButton, CreatorPostSubscribeRegular, CreatorPostSubscribeDiscount, MarkOffer },
  data: function() {
    return {
      isOffer: null,
      discountPercent: null,
      discountLimit: null,
    };
  },
  methods: {
    subscribe: function(post) {
      this.BuySubscription(post.user);
    },
    onDiscount: function(post) {
      const discountDateLimit = post.user?.subscriptions?.priceDiscount?.dateLimit;
      const isTimeLeft = this.$global.isTimeLeft(discountDateLimit);

      if (!isTimeLeft) return false;

      if (post.user?.subscriptions?.priceDiscount?.status === "on") {
        this.isOffer = true;
        this.discountPercent = post.user?.subscriptions?.priceDiscount?.percent;
        this.discountLimit = post.user?.subscriptions?.priceDiscount?.dateLimit;
        return true;
      }
    },
  },
};
</script>
