<template>
  <div class="subscribe-action">
    <a class="subscribe-button primary-button" @click="$emit('click')">
      <span v-if="loading"><iconic name="gspinner" /></span>
      <span v-else v-html="`${$locale['object']['subscribe']['subscribe-label-btn']} ${label || ''}`"></span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["loading", "label"],
};
</script>

<style lang="scss" scoped>
.subscribe-action {
  .subscribe-button {
    display: inline-block;
    width: 100%;
    background: $primary_color;
    max-width: 256px;
    padding: $mpadding/1.5 $mpadding;
    border-radius: 4pc;
  }
}
</style>
