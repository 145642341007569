<template>
  <div class="clock-timeleft" v-if="date">
    <div class="clock-timeleft-content">
      <table class="table" mauto>
        <tr>
          <td>
            <div class="clock"><iconic name="clock-solid" /></div>
          </td>
          <td>
            <div class="number">{{ daysLeft }}</div>
            <div class="label" grid>
              <span ellipsis>{{ $locale["object"]["time"]["days"] }}</span>
            </div>
          </td>
          <td>
            <div class="number">{{ hoursLeft }}</div>
            <div class="label" grid>
              <span ellipsis>{{ $locale["object"]["time"]["hours"] }}</span>
            </div>
          </td>
          <td>
            <div class="number">{{ minsLeft }}</div>
            <div class="label" grid>
              <span ellipsis>{{ $locale["object"]["time"]["minutes"] }}</span>
            </div>
          </td>
          <td>
            <div class="number">{{ secsLeft }}</div>
            <div class="label" grid>
              <span ellipsis>{{ $locale["object"]["time"]["seconds"] }}</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["date"],
  data: function() {
    return {
      interval: null,
      daysLeft: null,
      hoursLeft: null,
      minsLeft: null,
      secsLeft: null,
      timeUpdate: 1000,
    };
  },
  methods: {
    timeLeft: function() {
      const postDate = this.date?.split(".000")[0];
      if (!postDate) return false;
      let dateFrom = new Date(Date.now());
      let dateTo = new Date(postDate);

      const { days, hours, minutes, seconds } = this.$global.dateDiff(dateFrom, dateTo);
      const months = (days / 30).toFixed();

      this.daysLeft = days;
      this.hoursLeft = hours;
      this.minsLeft = minutes;
      this.secsLeft = seconds;
      return true;
    },
    startTime: function() {
      if (this.date && this.timeLeft()) {
        this.interval = setInterval(() => {
          this.timeLeft();
        }, this.timeUpdate);
      }
    },
  },
  mounted: function() {
    this.startTime();
  },
};
</script>

<style lang="scss" scoped>
.clock-timeleft {
  position: relative;
  &-content {
    margin: $mpadding/2 0 0 0;
    .table {
      tr {
        td {
          padding: 2px;
        }
      }
      .clock {
        font-size: 120%;
        margin: 0 5px 0 -5px;
      }
      .number,
      .label {
        background: #ffd9d9;
        color: #444444;
        width: 44px;
      }
      .number {
        font-size: 120%;
        font-weight: bold;
        margin: 0 0 2px 0;
      }
      .label {
        font-size: 80%;
        padding: 2px;
      }
    }
  }
}
</style>
