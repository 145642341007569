<template>
  <div class="post-discount" v-if="post">
    <p class="p-msg">¡{{ $locale["object"]["subscribe"]["subscribe-discount-label"] }}!</p>
    <div><ClockTimeLeft :date="dateLimit" /></div>
    <div class="post-subscribe-price">
      <div class="price-discount">
        <span v-html="`- ${percent} %`"></span>
      </div>
      <div class="price-before">
        <span v-html="`${number_format(priceByMonth, 2)}  <small>${$locale['usd']}</small>`"></span>
      </div>
      <div class="price-after">
        <span v-html="`${number_format(netPrice, 2)}  <small>${$locale['usd']}</small>`"></span>
      </div>
    </div>
  </div>
</template>

<script>
import ClockTimeLeft from "../widgets/ClockTimeLeft.vue";
export default {
  components: { ClockTimeLeft },
  props: ["post"],
  data: function() {
    return {
      percent: this.post.user?.subscriptions?.priceDiscount?.percent,
      dateLimit: this.post.user?.subscriptions?.priceDiscount?.dateLimit,
      priceByMonth: this.post.user?.subscriptions?.priceByMonth?.mount,
    };
  },
  computed: {
    netPrice: function() {
      return (this.priceByMonth * this.percent) / 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.post-discount {
  .price-discount {
    margin: 0 0 3px 0;
  }
  .price-before {
    font-size: 90%;
    margin: 0 0 3px 0;
    text-decoration: line-through;
  }
  .price-after {
    font-size: 120%;
  }
}
</style>
