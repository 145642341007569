<template>
  <div v-if="post">
    <p class="p-msg">{{ $locale["subscribe_to_watch"] }}</p>
    <div class="post-subscribe-price">
      <div v-if="isMonth">
        <span v-html="`${number_format(priceByMonth, 2)} <small>${$locale['usd']}</small> / `"></span>
        <b>{{ $locale["object"]["subscribe"]["subscribe-month"] }}</b>
      </div>
      <!-- <div v-if="isSemester">
        <span v-html="`${priceBySemester} <small>${$locale['usd']}</small> / `"></span>
        <b>{{ $locale["object"]["subscribe"]["subscribe-semester"] }}</b>
      </div>
      <div v-if="isYear">
        <span v-html="`${priceByYear} <small>${$locale['usd']}</small> / `"></span>
        <b>{{ $locale["object"]["subscribe"]["subscribe-year"] }}</b>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],
  data: function() {
    return {
      isMonth: this.post.user?.subscriptions?.priceByMonth?.status === "on",
      isSemester: this.post.user?.subscriptions?.priceBySemester?.status === "on",
      isYear: this.post.user?.subscriptions?.priceByYear?.status === "on",
      priceByMonth: this.post.user?.subscriptions?.priceByMonth?.mount,
      priceBySemester: this.post.user?.subscriptions?.priceBySemester?.mount,
      priceByYear: this.post.user?.subscriptions?.priceByYear?.mount,
    };
  },
};
</script>
